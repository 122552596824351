export function isInt(value) {
  return (
    !isNaN(value) &&
    parseInt(Number(value)) === value &&
    !isNaN(parseInt(value, 10))
  )
}

export function isProductionEnv() {
  return process.env.NODE_ENV === 'production'
}
