import React from 'react'
import styled from 'styled-components/macro'

import { colors } from '../../lib/globals'
import Sequence from './Sequence'

const Bullet = styled.div({
  backgroundColor: colors.baseBlue,
  lineHeight: '30px',
  height: 30,
  minWidth: 30,
  borderRadius: '50%',
  color: '#fff',
  verticalAlign: 'middle',
  textAlign: 'center',
  fontSize: 15,
})
const HorizontalLine = styled.div({
  height: 3,
  backgroundColor: '#000',
  width: '100%',
  marginLeft: 10,
})
function Sequences({ items }) {
  return (
    <div css={{ margin: '20px 0' }}>
      {items.map((item, index) => {
        return (
          <div css={{ margin: '20px 0 35px' }} key={index}>
            <div css={{ display: 'flex', alignItems: 'center' }}>
              <Bullet>{index + 1}</Bullet>
              <HorizontalLine />
            </div>
            <Sequence {...item} />
          </div>
        )
      })}
    </div>
  )
}

export default Sequences
