import React from 'react'
import styled from 'styled-components/macro'
import { Spring } from 'react-spring'
import ReactMarkdown from 'react-markdown'

import { colors } from '../../lib/globals'
import Modal from '../../lib/Modal'
import TextOnlyButton from '../../lib/TextOnlyButton'

const Wrapper = styled.div({
  maxWidth: 240,
  minWidth: 250,
  backgroundColor: colors.baseGreen,
  color: '#fff',
  marginLeft: 20,
  borderRadius: 4,
  padding: 26,
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 7,
})
const Meta = styled.div({
  fontSize: 15,
  marginBottom: 5,
  opacity: 0.5,
})
const Title = styled.div({
  fontSize: 35,
  marginBottom: 40,
  lineHeight: '38px',
})
const Text = styled.div({ marginBottom: 10 })
const BottomSection = styled.div({
  marginTop: 'auto',
  display: 'flex',
  alignItems: 'center',
})

function Collapsed({ meta }) {
  return <Wrapper css={{ opacity: 0.5 }}>{meta}</Wrapper>
}

function renderContent(examples) {
  return (
    <ReactMarkdown
      css={`
        table {
          margin: 5px 0 40px;
        }
        table,
        thead,
        tbody,
        tfoot,
        tr,
        th,
        td {
          border-spacing: 0;
          text-align: left;
        }
        table,
        td,
        th {
          border: 1px solid #dedede;
        }
        table {
          table-layout: fixed;
          border-right-width: 0;
          border-bottom-width: 0;
        }
        th {
          background: #f5f5f5;
        }
        td,
        th {
          padding: 5px;
          border-left-width: 0;
          border-top-width: 0;
        }
      `}
      source={examples}
    />
  )
}

function Expanded({ title, subTitle, shortDescription, examples }) {
  return (
    <Spring
      from={{ opacity: 0.5 }}
      config={{ duration: 300 }}
      to={{ opacity: 1 }}
    >
      {props => (
        <Wrapper style={props}>
          <Meta>{title}</Meta>
          <Title>{subTitle}</Title>
          <Text>{shortDescription.content[0].content[0].value}</Text>
          <BottomSection>
            <Modal
              hasSheet
              title={
                <div
                  css={{
                    fontWeight: 600,
                    fontSize: 20,
                    color: '#4e95f3',
                    marginLeft: 5,
                  }}
                >
                  Exempel på {title.toLowerCase()}
                </div>
              }
              content={renderContent(examples)}
              render={props => {
                return (
                  <TextOnlyButton
                    css={{ color: '#fff', textDecoration: 'underline' }}
                    {...props}
                  >
                    Se exempel
                  </TextOnlyButton>
                )
              }}
            />
          </BottomSection>
        </Wrapper>
      )}
    </Spring>
  )
}

function Explanation({ currentFieldIndex, fieldInformation }) {
  // const obj = data[currentFieldIndex]
  return (
    <div>
      {fieldInformation.map((item, index) => {
        if (index === currentFieldIndex)
          return <Expanded key={index} {...item} />
        return <Collapsed key={index} meta={item.title} />
      })}
    </div>
  )
}

export default Explanation
