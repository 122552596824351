import styled from 'styled-components/macro'

const TransparentButton = styled.button({
  backgroundColor: 'transparent',
  border: 'none',
  padding: 0,
  cursor: 'pointer',
})

export default TransparentButton
