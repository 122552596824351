import React, { Component } from 'react'
import styled from 'styled-components/macro'
import AntModal from 'antd/lib/modal'

import './modal-styling.css'
import { boxShadows } from './globals'

const Wrapper = styled.div(({ hasSheet }) => ({
  position: 'relative',
  whiteSpace: 'pre-line',
  fontFamily: 'Source Sans Pro,Open Sans,sans-serif',
  fontSize: 16,
  color: '#283c46',
  borderRadius: '4px',
  background: hasSheet ? '#fff' : 'transparet',
  boxShadow: hasSheet ? boxShadows.sheet : 'none',
  padding: hasSheet ? 30 : 0,
}))

class Modal extends Component {
  state = { visible: this.props.isModalVisible || false }

  handleHideModalClick = (event, mutationFunction, data) => {
    event.preventDefault()
    mutationFunction().then(({ data }) => {
      this.setState({ visible: false })
    })
  }
  showModal = e => {
    e.preventDefault()
    this.setState({
      visible: true,
    })
  }
  handleOk = e => {
    this.setState({
      visible: false,
    })
  }
  handleCancel = e => {
    this.setState({
      visible: false,
    })
  }
  render() {
    const { render, title, content, hasSheet } = this.props
    return (
      <div style={{ display: 'flex' }}>
        {render({ onClick: this.showModal })}
        <AntModal
          title={title}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          width={700}
          bodyStyle={{ padding: '15px 30px 40px' }}
          style={{ background: '#f7f9ff', paddingBottom: 0, borderRadius: 4 }}
          css={`
            .ant-modal-content {
              background: transparent;
            }
            .ant-modal-header {
              background: transparent;
              border: none;
              padding: 25px 30px 15px;
            }
            .ant-modal-close-x {
              width: 55px;
              line-height: 50px;
              font-size: 20px;
            }
          `}
        >
          <Wrapper hasSheet={hasSheet}>{content}</Wrapper>
        </AntModal>
      </div>
    )
  }
}

export default Modal
