import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

import Button from '../lib/Button'
import Sheet from '../lib/Sheet'
import TextOnlyButton from '../lib/TextOnlyButton'
import Modal from '../lib/Modal'
import imgExample from '../lib/images/first-example.png'

const Section = styled.div({
  borderBottom: '1px solid #eee',
  marginBottom: 40,
  paddingBottom: 20,
})
const Paragraph = styled.div({
  marginBottom: 15,
})
const Image = styled.img({
  width: 640,
  margin: '20px 0 40px',
})
const BottomSection = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

function Start({ introParagraphs }) {
  return (
    <Sheet css={{ marginBottom: 100 }}>
      {introParagraphs.map(({ emoji, title, text }, index) => (
        <Section key={index}>
          <Paragraph css={{ fontWeight: 600, fontSize: 18 }}>
            <span css={{ marginRight: 5 }}>{emoji}</span> {title}
          </Paragraph>
          <Paragraph
            css={{
              whiteSpace: 'pre-line',
            }}
          >
            {text}
          </Paragraph>
        </Section>
      ))}
      <BottomSection>
        <Modal
          title={
            <div css={{ fontWeight: 600, fontSize: 25 }}>
              Exempel på en färdig sammanfattning
            </div>
          }
          content={<Image src={imgExample} />}
          render={props => {
            return (
              <TextOnlyButton {...props}>
                Exempel på sammanfattning
              </TextOnlyButton>
            )
          }}
        />
        <Button as={Link} to="/mapping">
          Sätt igång →
        </Button>
        <div css={{ opacity: 0, width: 190 }} />
      </BottomSection>
    </Sheet>
  )
}

export default Start
