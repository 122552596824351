import React from 'react'
import styled from 'styled-components/macro'

import { colors } from '../../lib/globals'

const MappingField = styled.div({
  backgroundColor: colors.textAreaBG,
  borderRadius: 15,
  padding: 20,
  margin: '0 0 15px 35px',
})
const Separator = styled.div({
  width: '100%',
  margin: '15px 0',
  borderBottom: '1px dashed #ccc',
})

function Sequence({ situation, obstacle, wish }) {
  return (
    <div>
      <div css={{ margin: '5px 0 15px 40px' }}>
        <div
          css={{
            fontWeight: 600,
            marginRight: 5,
            fontSize: 25,
            lineHeight: '25px',
          }}
        >
          Situation
        </div>
        <div>{situation}</div>
      </div>
      <MappingField>
        <div css={{ fontWeight: 600, marginBottom: 2 }}>
          Studiehindrande beteenden
        </div>
        <div>{obstacle}</div>
        <Separator />
        <div css={{ fontWeight: 600, marginBottom: 2 }}>
          Studiefrämjande beteenden
        </div>
        <div>{wish}</div>
      </MappingField>
    </div>
  )
}

export default Sequence
