import React from 'react'
import { Helmet } from 'react-helmet'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom'

import Login from './screens/Login'
import Start from './screens/Start'
import Mapping from './screens/Mapping'
import Summary from './screens/Summary'
import TopBar from './lib/TopBar'
import PageContainer from './lib/PageContainer'
import Steps from './lib/Steps'
import ContentfulProvider from './ContentfulProvider'
import { isProductionEnv } from './lib/utilFunctions'
import FullStory from './lib/FullStory'

function Routing({ isAuthenticated }) {
  if (isAuthenticated && isAuthenticated.value) {
    if (isProductionEnv()) {
      window.Intercom('boot', {
        user_id: isAuthenticated.username,
        app_id: 're6bwqlr',
      })
    }
    return (
      <ContentfulProvider>
        {({ introParagraphs, fieldInformation }) => {
          return (
            <PageContainer>
              {isProductionEnv() && <FullStory />}
              <Steps />
              <Switch>
                <Route
                  path="/start"
                  render={() => (
                    <>
                      <Helmet>
                        <title>Introduktion</title>
                      </Helmet>
                      <Start {...{ introParagraphs }} />
                    </>
                  )}
                />
                <Route
                  path="/mapping"
                  render={() => (
                    <>
                      <Helmet>
                        <title>Kartläggning</title>
                      </Helmet>
                      <Mapping {...{ fieldInformation }} />
                    </>
                  )}
                />
                <Route
                  path="/summary"
                  render={props => (
                    <>
                      <Helmet>
                        <title>Sammanfattning</title>
                      </Helmet>
                      <Summary {...props} />
                    </>
                  )}
                />
                <Redirect to={'/start'} />
              </Switch>
            </PageContainer>
          )
        }}
      </ContentfulProvider>
    )
  } else {
    return (
      <Switch>
        <Route path="/login" component={Login} />
        <Redirect to="/login" />
      </Switch>
    )
  }
}

function App() {
  const isAuthenticated = JSON.parse(
    window.localStorage.getItem('isAuthenticated'),
  )
  return (
    <Router>
      <>
        <TopBar isAuthenticated={isAuthenticated} />
        <Routing isAuthenticated={isAuthenticated} />
      </>
    </Router>
  )
}

export default App
