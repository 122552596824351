import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const ControlledInput = styled.input({
  ':focus': {
    border: '1px solid #a4d2ff',
    boxShadow: '0 0 6px rgba(27, 106, 201, 0.5)',
  },
  '::placeholder': {
    color: '#bbb',
  },
  fontSize: 16,
  padding: '10px 15px',
  boxSizing: 'border-box',
  width: '100%',
  border: '1px solid #ddd',
  outline: 'none',
  marginBottom: 22,
  marginTop: 4,
  borderRadius: 4,
  transition: 'box-shadow 0.15s ease-in-out',
  color: '#283c46',
})

ControlledInput.propTypes = {
  inputType: PropTypes.oneOf(['text', 'number', 'password', 'email', 'file'])
    .isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
}

export default ControlledInput
