import React from 'react'
import styled from 'styled-components/macro'
import ReactHoverObserver from 'react-hover-observer'

import { colors } from './globals'
import TransparentButton from './TransparentButton'

const Button = styled.button(({ isHovering }) => ({
  color: colors.baseGreen,
  ...(isHovering && {
    textDecoration: 'underline',
  }),
}))

const TextOnlyButton = ({ children, ...rest }) => (
  <ReactHoverObserver>
    {({ isHovering }) => (
      <Button as={TransparentButton} isHovering={isHovering} {...rest}>
        {children}
      </Button>
    )}
  </ReactHoverObserver>
)

export default TextOnlyButton
