import { createGlobalStyle } from 'styled-components/macro'
import styledNormalize from 'styled-normalize'

require('typeface-source-sans-pro')

const GlobalStyles = createGlobalStyle`
  ${styledNormalize}

  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: normal;
    overflow-y: scroll;
    background-color: #f6f2ef;
    color: #283c46;    
    position: relative;
    text-size-adjust: 100%;
    min-height: 700px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.4px;
    
    input:focus,
    select:focus,
    textarea:focus,
    button:focus {
      outline: none;
    }
  }
  a {
    color: inherit;
    text-decoration: none;
    :focus, :hover, :active {
      text-decoration: underline;
    }
  }
  p {
    margin-block-start: 0;
  }
`
export default GlobalStyles
