import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

import Sheet from '../../lib/Sheet'
import TextOnlyButton from '../../lib/TextOnlyButton'
import Button from '../../lib/Button'
import { colors } from '../../lib/globals'
import { isProductionEnv } from '../../lib/utilFunctions'

const StyledSheet = styled(Sheet)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-around',
  minHeight: 200,
})

function Selector({ handleShowForm, items }) {
  const itemsCount = items.length
  return (
    <StyledSheet>
      {itemsCount < 3 && (
        <>
          <TextOnlyButton
            onClick={() => handleShowForm(true)}
            css={{ color: colors.baseBlue }}
          >
            Lägg till {itemsCount > 0 && 'ytterligare '}kartläggning
          </TextOnlyButton>
          {itemsCount > 0 && <div css={{ margin: '0 5px' }}>eller</div>}
        </>
      )}
      {itemsCount > 0 && (
        <Button
          as={Link}
          onClick={() => {
            if (isProductionEnv()) {
              window.Intercom('trackEvent', 'submitted-mapping', {
                sequenceCount: itemsCount,
              })
            }
          }}
          to={{
            pathname: '/summary',
            state: { items },
          }}
        >
          Gå vidare →
        </Button>
      )}
    </StyledSheet>
  )
}

export default Selector
