import React, { Component } from 'react'
import styled from 'styled-components/macro'

import ControlledInput from '../../lib/ControlledInput'
import Button from '../../lib/Button'
import Sheet from '../../lib/Sheet'
import { isInt } from '../../lib/utilFunctions'

const Label = styled.span({
  fontSize: 14,
  fontWeight: 600,
  opacity: 0.8,
})
const BottomSection = styled.div({
  display: 'flex',
  marginTop: 20,
  alignItems: 'center',
})

class Form extends Component {
  state = this.props.formValues
  handleChange = event => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }
  validateForm = () => {
    const { obstacle, situation, wish } = this.state
    const isInvalid = !obstacle || !situation || !wish
    return isInvalid
  }
  handleSubmit = event => {
    event.preventDefault()
    this.props.handleSaveItem(this.state, this.props.index)
  }
  renderInput = ({ fieldId, index, meta }) => (
    <span key={meta.title}>
      <Label>{meta.title}</Label>
      <ControlledInput
        autoFocus={index === 0}
        onFocus={() => this.props.handleCurrentField(index)}
        inputType="text"
        name={fieldId}
        value={this.state[fieldId]}
        onChange={this.handleChange}
      />
    </span>
  )
  render() {
    const { index, fieldInformation } = this.props
    const fieldIds = fieldInformation.map(item => item.fieldId)
    return (
      <Sheet css={{ marginBottom: 20, paddingTop: 25 }}>
        <form onSubmit={this.handleSubmit}>
          {fieldIds.map((fieldId, index) =>
            this.renderInput({ fieldId, index, meta: fieldInformation[index] }),
          )}
          <BottomSection>
            <Button blue shrunk type="submit" disabled={this.validateForm()}>
              Spara
            </Button>
            <a
              href="/"
              onClick={event => {
                event.preventDefault()
                isInt(index)
                  ? this.props.handleRemoveItem(index)
                  : this.props.handleShowForm(false)
              }}
              css={{ marginLeft: 'auto', color: '#aaa' }}
            >
              Ta bort
            </a>
          </BottomSection>
        </form>
      </Sheet>
    )
  }
}

export default Form
