import React from 'react'
import styled from 'styled-components/macro'
import ReactHoverObserver from 'react-hover-observer'

import { colors } from '../lib/globals'
import TransparentButton from './TransparentButton'

const StyledButton = styled.button(
  ({ shrunk, blue, ishovering, isFullWidth, disabled, white, small }) => ({
    backgroundColor: colors.baseGreen,
    color: '#fff',
    borderRadius: '4px',
    padding: '12px 60px',
    display: 'inline-block',
    textDecoration: 'none !important',
    ...(ishovering && {
      backgroundColor: '#1e9251',
    }),
    ...(shrunk && {
      padding: '12px 20px',
    }),
    ...(isFullWidth && {
      width: '100%',
    }),
    ...(blue && {
      backgroundColor: '#1b6ac9',
      color: '#fff',
    }),
    ...(white && {
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      color: 'inherit',
      ...(ishovering && {
        backgroundColor: '#eee',
      }),
    }),
    ...(small && {
      padding: '5px 10px',
    }),
    ...(disabled && {
      opacity: 0.7,
    }),
  }),
)

class Button extends React.Component {
  render() {
    const { children, ...rest } = this.props
    return (
      <ReactHoverObserver>
        {({ isHovering }) => (
          <StyledButton
            as={TransparentButton}
            ishovering={isHovering ? 1 : 0}
            {...rest}
          >
            {children}
          </StyledButton>
        )}
      </ReactHoverObserver>
    )
  }
}
export default Button
