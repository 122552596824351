import styled from 'styled-components/macro'

import { boxShadows } from './globals'

const Sheet = styled.div({
  backgroundColor: '#fff',
  padding: '30px',
  boxShadow: boxShadows.sheet,
  borderRadius: '4px',
})

export default Sheet
