import React, { Component } from 'react'
import styled from 'styled-components/macro'

import Sheet from '../lib/Sheet'
import { colors } from '../lib/globals'
import Sequences from './SummaryComponents/Sequences'
import Buttons from './SummaryComponents/Buttons'
import PrimaryInfo from './SummaryComponents/PrimaryInfo'

const Title = styled.div({
  fontSize: 30,
  fontWeight: 700,
})
const SubTitle = styled.div({
  display: 'flex',
  alignItems: 'center',
})
const Line = styled.div({
  height: 1,
  backgroundColor: 'rgba(0,0,0,0.1)',
  width: '100%',
  marginBottom: '-3px',
})
const Content = styled.div({
  fontSize: 20,
  margin: '0 15px',
  whiteSpace: 'nowrap',
})
const FreeText = styled.div({
  backgroundColor: colors.textAreaBG,
  borderRadius: 15,
  padding: 10,
  minHeight: 300,
  margin: '10px 0 30px',
})
const StyledSheet = styled(Sheet)({
  marginBottom: 100,
  '@media print': { boxShadow: 'none !important' },
})

class Summary extends Component {
  render() {
    const { items } = this.props.location.state
    return (
      <StyledSheet ref={el => (this.componentRef = el)}>
        <div css={{ display: 'flex' }}>
          <Title>Sammanfattning</Title>
          <Buttons componentRef={() => this.componentRef} />
        </div>
        <PrimaryInfo />
        <Sequences items={items} />
        <SubTitle>
          <Line />
          <Content>Vidare analys</Content>
          <Line />
        </SubTitle>
        <FreeText />
      </StyledSheet>
    )
  }
}

export default Summary
