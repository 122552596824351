import React, { Component } from 'react'
import 'styled-components/macro'
import { createClient } from 'contentful'
import { ScaleLoader } from 'react-spinners'

const contentfulClient = createClient({
  space: '62ncoiyebkcj',
  accessToken:
    'b5a47d97003b5a60ad012070580c47d994bfd1d8b9b8744c2256a3ee21bfe90b',
})

const filterResponse = (items, propertyName) =>
  items
    .filter(item => item.sys.contentType.sys.id === propertyName)
    .sort((a, b) => a.fields.order - b.fields.order)
    .map(item => item.fields)

export class ContentfulProvider extends Component {
  state = {
    introParagraphs: [],
    fieldInformation: [],
    isLoading: true,
  }
  componentDidMount() {
    contentfulClient.getEntries().then(({ items }) => {
      const introParagraphs = filterResponse(items, 'introductionParagraph')
      const fieldInformation = filterResponse(items, 'fieldInformation')
      this.setState({
        introParagraphs,
        fieldInformation,
        isLoading: false,
      })
    })
  }

  render() {
    const { isLoading, introParagraphs, fieldInformation } = this.state
    const { children } = this.props
    if (isLoading) {
      return (
        <div
          css={{ display: 'flex', justifyContent: 'center', marginTop: 150 }}
        >
          <ScaleLoader color="#b8bcbd" />
        </div>
      )
    } else {
      return children({ introParagraphs, fieldInformation })
    }
  }
}

export default ContentfulProvider
