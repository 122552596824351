import React, { Component } from 'react'
import styled from 'styled-components/macro'

import Selector from './MappingComponents/Selector'
import Form from './MappingComponents/Form'
import DoneItem from './MappingComponents/DoneItem'
import Explanation from './MappingComponents/Explanation'
import { isInt } from '../lib/utilFunctions'

const Wrapper = styled.div({
  display: 'flex',
})

const defaultFormState = { obstacle: '', situation: '', wish: '' }

class Mapping extends Component {
  state = {
    items: [],
    currentFieldIndex: 0,
    indexInEditMode: null,
    isNewFormVisible: true,
  }
  handleShowForm = bool => {
    this.setState({ isNewFormVisible: bool })
  }
  handleEditItem = index => {
    this.setState({ indexInEditMode: index })
  }
  handleRemoveItem = index => {
    this.setState(prevState => ({
      items: prevState.items.filter((_, i) => i !== index),
      indexInEditMode: null,
    }))
  }
  handleSaveItem = (item, index) => {
    if (index !== undefined) {
      let items = [...this.state.items]
      items[index] = item
      this.setState({
        items,
        indexInEditMode: null,
      })
    } else {
      this.setState(prevState => ({
        items: [...prevState.items, item],
        isNewFormVisible: false,
      }))
    }
  }
  handleCurrentField = index => {
    this.setState({ currentFieldIndex: index })
  }
  renderAdder = () => {
    if (this.state.isNewFormVisible) {
      return (
        <Form
          handleCurrentField={this.handleCurrentField}
          handleShowForm={this.handleShowForm}
          formValues={defaultFormState}
          handleSaveItem={this.handleSaveItem}
          fieldInformation={this.props.fieldInformation}
        />
      )
    } else {
      return (
        <Selector
          items={this.state.items}
          handleShowForm={this.handleShowForm}
        />
      )
    }
  }
  render() {
    const { items, currentFieldIndex, indexInEditMode } = this.state
    const { fieldInformation } = this.props
    return (
      <Wrapper>
        <div css={{ width: '100%' }}>
          {items.map((item, index) => {
            if (indexInEditMode === index) {
              return (
                <Form
                  key={index}
                  handleCurrentField={this.handleCurrentField}
                  handleShowForm={this.handleShowForm}
                  handleRemoveItem={this.handleRemoveItem}
                  formValues={item}
                  index={index}
                  handleSaveItem={this.handleSaveItem}
                  fieldInformation={fieldInformation}
                />
              )
            } else {
              return (
                <DoneItem
                  key={index}
                  index={index}
                  handleEditItem={this.handleEditItem}
                  {...item}
                />
              )
            }
          })}
          {!isInt(indexInEditMode) && this.renderAdder()}
        </div>
        <Explanation
          fieldInformation={fieldInformation}
          currentFieldIndex={currentFieldIndex}
        />
      </Wrapper>
    )
  }
}

export default Mapping
