import React from 'react'
import styled from 'styled-components/macro'

import Sheet from '../../lib/Sheet'
import TransparentButton from '../../lib/TransparentButton'
import { colors } from '../../lib/globals'

const Inner = styled.div({
  width: 320,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: '#aaa',
  textAlign: 'left',
})
const Ellipsis = styled.div({
  color: '#aaa',
  marginLeft: 'auto',
  fontSize: 30,
  lineHeight: 0,
})

function DoneItem({ obstacle, index, handleEditItem }) {
  return (
    <TransparentButton
      css={{ width: '100%' }}
      onClick={() => handleEditItem(index)}
    >
      <Sheet
        css={{
          margin: '0 0 15px',
          borderLeft: `4px solid ${colors.baseBlue}`,
          display: 'flex',
        }}
      >
        <Inner>{obstacle}</Inner>
        <Ellipsis>&#8230;</Ellipsis>
      </Sheet>
    </TransparentButton>
  )
}

export default DoneItem
